/* jshint esversion: 6 */
/* global, document, window */

// import '@babel/polyfill';
import pollyfill from './scripts/polyfill';

function load() {
    // if (!document.cookie.match(`(^|;) ?_kfl=([^;]*)(;|$)`)) {
    //     import(/* webpackMode: "lazy", webpackChunkName: "fontLoader", webpackPreload: true */'./scripts/fonts').then(({ default: initFonts }) => initFonts());
    // }
    // else {
    //     document.body.classList.add('fonts-loaded');
    // }
   // document.body.classList.add('fonts-loaded');
            const observer = lozad();
            observer.observe();

    // initFonts();

    import(/* webpackMode: "lazy", webpackChunkName: "jquery", webpackPreload: true */'jquery').then(({ default: jQuery }) => {
        window.$ = jQuery;
        window.jQuery = jQuery;


        Promise.all([
            import(/* webpackChunkName: "lazyLoad", webpackPreload: true */'./scripts/lazyLoad'),
        //    import(/* webpackChunkName: "categoryList", webpackPreload: true */'./components/categoryList'),
        ]).then(data => {
           // const [{ default: lazyLoad }, { default: categoryListInit }] = data;
        //    categoryListInit();
            import(/* webpackChunkName: "navigation", webpackPreload: true */'./components/navigation').then(() => {
                import(/* webpackChunkName: "categoryFilter", webpackPreload: true */'./components/categoryFilter');
            });
        });

        import(/* webpackChunkName: "common", webpackPreload: true */'./components/common').then(() => {
            import(/* webpackChunkName: "_category_modules_", webpackPreload: true */'./modules/category');

            import(/* webpackChunkName: "_category_modules_", webpackPreload: true */'./modules/filter');
            import(/* webpackChunkName: "moduleLoader", webpackPreload: true */'./scripts/moduleLoader').then(({ default: moduleLoader }) => {
                moduleLoader.addModule('footer', $('.footer-container'), false).then(loaded => import(/* webpackChunkName: "footer" */'./components/footer').then(_ => loaded(_)));
            });
        });
    });
}

pollyfill(() => {
    const raf = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
    if (raf) {
        raf(() => {
            window.setTimeout(load, 0);
        });
    }
    else {
        window.addEventListener('load', load);
    }
});
