
export default callback => {
    const needsPolyFill = () => {
        let need = typeof Promise === 'undefined';
        try {
            document.querySelectorAll('body')[Symbol.iterator]();
        }
        catch (err) {
            need = true;
        }
        return need;
    };

    if (needsPolyFill()) {
        const polyfill = document.createElement('script');
        polyfill.type = `text/javascript`;
        polyfill.src = `https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/7.0.0/polyfill.min.js`;
        polyfill.defer = true;
        polyfill.async = true;
        polyfill.onload = callback;
        document.head.appendChild(polyfill);
    }
    else {
        callback();
    }
};
